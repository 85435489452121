/* eslint-disable max-lines */
import { BaseEntity, OffscreenBaseData } from 'offscreen-canvas-proxy';
import type { TierResult } from 'detect-gpu';
import {
    PerspectiveCamera,
    WebGLRenderer,
    Scene,
    sRGBEncoding,
    AmbientLight,
    DirectionalLight,
    DirectionalLightHelper,
    PMREMGenerator,
    Vector3,
    RepeatWrapping,
    Texture,
    ImageBitmapLoader,
    ImageLoader,
    Mesh,
    LoadingManager,
    Raycaster,
    Vector2,
    Object3D,
    CameraHelper,
    // AnimationMixer,
    // LoopOnce,
    Clock,
    Fog,
    EquirectangularReflectionMapping,
    RGBAFormat,
    TextureLoader,
    ReinhardToneMapping,
} from 'three';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
// import GLTFMeshGPUInstancingExtension from 'three-gltf-extensions/loaders/EXT_mesh_gpu_instancing/EXT_mesh_gpu_instancing';
// import GLTFMeshGPUInstancingExtension from '../../extensions/EXT_mesh_gpu_instancing';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';
import loadFont from 'load-bmfont';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import lerp from 'lerp';
import { createInstances } from './create-instances';
import { baseEasing } from '../../animations/easings';
import { initPostprocessing } from './postprocessing';
import { getMaterialByName } from './utils';
import { Mark } from './Mark';

interface MainThreadData {
    dpr: number;
    width: number;
    height: number;
    gpuTier: TierResult;
    debug: boolean;
}

export type CanvasData = OffscreenBaseData & MainThreadData;

type Hint = {
    text: string;
    parentName: string;
    position: [number, number, number];
};

export interface Canvas extends BaseEntity {
    canvas: HTMLCanvasElement;
    renderer: WebGLRenderer;
    camera: PerspectiveCamera;
    scene: Scene;
    ambientLight: AmbientLight;
    directionalLight: DirectionalLight;
    controls: any;
    // composer: EffectComposer | null;
    textureLoader: TextureLoader;
    imageLoader: ImageLoader | ImageBitmapLoader;
    gltf: GLTF | null;
    waterNormalsTexture: Texture;
    postprocessing?: Record<string, any>;
    raycaster: Raycaster;
    bolnica?: Object3D;
    raycastableObjects: Object3D[];
    clock: Clock;
    distance: number;
    hints: Mark[];
    skyParameters: any;
    _waitForInitialAnimationSignalCallback: null | (() => void);
    _waitForStartCallback: null | (() => void);
}

// const sunColor = 0xffe5c4;
// const waterColor = 0x001e0f;

export class Canvas extends BaseEntity {
    constructor(readonly options: CanvasData) {
        super(options);

        this.animate = this.animate.bind(this);

        this.options = options;
        this.canvas = options.canvas;

        this.state = {
            shouldRender: true,
            width: options.width,
            height: options.height,
            dpr: options.dpr,
            pointer: new Vector2(),
            lookAt: new Vector3(),
            progress: 0,
            allowRaycasting: false,
            cameraMovementMult: 2,
            delta: 0,
            renderInterval:
                1 /
                // eslint-disable-next-line no-nested-ternary
                (this.options.gpuTier.fps && this.options.gpuTier.fps > 250
                    ? 60
                    : this.options.gpuTier.fps && this.options.gpuTier.fps < 25
                    ? 24
                    : 30),
            // USE_POSTPROCESSING: this.options.gpuTier.isMobile
            //     ? false
            //     : this.options.gpuTier.gpu === 'apple gpu (Apple GPU)' ||
            //       (this.options.gpuTier.fps ? this.options.gpuTier.fps > 30 : this.options.gpuTier.tier > 1),
            USE_POSTPROCESSING: false,
            USE_WATER: false,
            // this.options.gpuTier.gpu === 'apple gpu (Apple GPU)' ||
            // (this.options.gpuTier.fps ? this.options.gpuTier.fps > 20 : this.options.gpuTier.tier > 1),
        };

        if (!this.canvas.style) {
            (this.canvas.style as any) = {};
            (this.canvas.style as any).width = options.width;
            (this.canvas.style as any).height = options.height;
        }

        this.canvas.width = options.width * options.dpr;
        this.canvas.height = options.height * options.dpr;

        this.clock = new Clock();
        this.raycastableObjects = [];
        this.distance = 0;

        this.renderer = new WebGLRenderer({
            canvas: this.options.canvas,
            antialias: !this.state.USE_POSTPROCESSING && this.state.dpr <= 2,
            alpha: false,
            powerPreference: 'high-performance',
            stencil: !this.state.USE_POSTPROCESSING,
            depth: !this.state.USE_POSTPROCESSING,
        });
        this.renderer.setSize(this.state.width, this.state.height);
        this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.physicallyCorrectLights = true;
        this.renderer.setPixelRatio(this.state.dpr);
        this.renderer.toneMapping = ReinhardToneMapping;
        this.renderer.toneMappingExposure = 1.2;

        this.camera = new PerspectiveCamera(36.9, this.state.width / this.state.height, 1, 500);
        window.camera = this.camera;
        this.camera.position.set(0, 1.66533, 0);

        this.scene = new Scene();
        // this.scene.overrideMaterial = new MeshBasicMaterial();
        // this.scene.fog = this.options.gpuTier.isMobile ? null : new Fog(0xb6b5b7, 40, 600);

        /**
         * Raycaster
         */

        this.raycaster = new Raycaster(undefined, undefined, 0, 500);

        /**
         * Loaders
         */
        this.imageLoader = new ImageLoader();
        this.textureLoader = new TextureLoader();
        this.exrLoader = new EXRLoader();

        this.textureLoader.load(`${PUBLIC_PATH}img/gradient3.jpg`, (texture) => {
            this.scene.background = texture;
        });

        const pmremGenerator = new PMREMGenerator(this.renderer);
        this.pmremGenerator = pmremGenerator;

        this.exrLoader.load(`${PUBLIC_PATH}img/envmap.exr`, (texture) => {
            texture.mapping = EquirectangularReflectionMapping;
            const hdrEquirectRenderTarget = pmremGenerator.fromEquirectangular(texture);
            this.scene.environment = hdrEquirectRenderTarget.texture;
            pmremGenerator.dispose();
        });
    }

    async load(callback: (data: { loaded: number; total: number }) => void) {
        const manager = new LoadingManager();

        manager.onProgress = (url, itemsLoaded, itemsTotal) => {
            callback({ loaded: itemsLoaded, total: itemsTotal });
        };

        const gltfLoader = new GLTFLoader(manager);
        // gltfLoader.register((parser) => new GLTFMeshGPUInstancingExtension(parser, { InstancedMesh, Object3D }));
        const ktx2Loader = new KTX2Loader(manager)
            .setTranscoderPath(`${PUBLIC_PATH}basis/`)
            .detectSupport(this.renderer);
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath(`${PUBLIC_PATH}draco/`);
        gltfLoader.setDRACOLoader(dracoLoader);
        gltfLoader.setKTX2Loader(ktx2Loader);
        gltfLoader.setPath(`${PUBLIC_PATH}gltf/`);
        gltfLoader.load('city22-opt.glb', (gltf) => {
            dracoLoader.dispose();
            ktx2Loader.dispose();
            this.gltf = gltf;
            gltf.scene.add(this.camera);
            this.scene.add(gltf.scene);

            const water = gltf.scene.getObjectByName('GROUND_2') as Mesh | null;

            if (water) {
                const bumpMap = this.textureLoader.load(`${PUBLIC_PATH}img/water-bump.jpg`);
                bumpMap.wrapS = RepeatWrapping;
                bumpMap.wrapT = RepeatWrapping;
                bumpMap.repeat.set(6, 6);
                water.material.bumpMap = bumpMap;
                water.material.bumpScale = 0.65;

                const fountainWater = gltf.scene.getObjectByName('fontan03') as Mesh | null;

                if (fountainWater) {
                    fountainWater.material = fountainWater.material.clone();

                    if (fountainWater.material.bumpMap) {
                        fountainWater.material.bumpMap = fountainWater.material.bumpMap.clone();
                    }
                }
            }

            this.bolnica = gltf.scene.getObjectByName('bolnica');
            const bolnicaY = this.bolnica ? this.bolnica.position.y : 0;

            createInstances(this);

            if (this.bolnica) {
                gsap.set(this.bolnica.position, { y: bolnicaY - 10 });
            }

            const posStartVec = new Vector3(36.475418675023434, 24.304733566747192, -9.048898961333737);
            const posStartViewVec = new Vector3(60.578500086484574, 11.248154189519276, 45.184943129616215);
            const pos1Vec = new Vector3(40.44214829397646, 5.6319, 2.1747981211544474);
            const pos2Vec = new Vector3(-2.9395, 5.6319, 26.736);
            const pos3Vec = new Vector3(-16.68694113440256, 5.6319, -28.491268340004915);
            const posFinishVec =
                this.state.width <= 767 ? new Vector3(109.904, 80.478, -93.615) : new Vector3(84.904, 59.478, -33.615);

            this.cameraTl = gsap.timeline({
                paused: true,
                defaults: {
                    duration: 1,
                    ease: baseEasing,
                    onUpdate: () => {
                        this.scheduleRendering();
                    },
                },
            });

            this.cameraTl
                .to(this.camera.position, { duration: 3, x: pos1Vec.x, y: pos1Vec.y, z: pos1Vec.z })
                .to(this.camera.position, { x: pos2Vec.x, duration: 2, y: pos2Vec.y, z: pos2Vec.z })
                .to(this.camera.position, { x: pos3Vec.x, duration: 2, y: pos3Vec.y, z: pos3Vec.z })
                .to(this.camera.position, { duration: 3, x: posFinishVec.x, y: posFinishVec.y, z: posFinishVec.z });

            this.camera.position.copy(posStartVec);

            if (this.bolnica) {
                gsap.to(this.bolnica.position, { duration: 1.5, delay: 1.5, y: bolnicaY, ease: baseEasing });
            }

            const initialCameraTransitionDuration = 4;

            gsap.to(this.camera.position, {
                duration: initialCameraTransitionDuration,
                delay: 1.5,
                x: posStartViewVec.x,
                y: posStartViewVec.y,
                z: posStartViewVec.z,
                ease: CustomEase.create('custom', 'M0,0,C0.2,0,0.54,0.38,0.654,0.682,0.734,0.882,0.822,1,1,1'),
                onStart: () => {
                    setTimeout(() => {
                        if (this._waitForInitialAnimationSignalCallback) {
                            this._waitForInitialAnimationSignalCallback();
                            this._waitForInitialAnimationSignalCallback = null;
                        }
                    }, initialCameraTransitionDuration * 1000 * 0.65);
                },
                onComplete: () => {
                    if (this._waitForStartCallback) {
                        this._waitForStartCallback();
                        this._waitForStartCallback = null;
                    }
                },
            });

            if (this.options.debug) {
                this.controls?.dispose();
                // this.offscreenOrbitControlsStart();
            }

            function cloneMaterial(group: Object3D, materialName: string) {
                const material = getMaterialByName(gltf.scene, materialName);

                if (material) {
                    const materialClone = material.clone();
                    materialClone.name = `${materialName}_${group.name}`;
                    group.traverse((child) => {
                        if (child.isMesh && child.material.name === materialName) {
                            child.material = materialClone;
                        }
                    });
                }
            }

            if (this.bolnica) {
                /**
                 * Клонируем, чтобы нормально работал рэйкастинг.
                 */
                cloneMaterial(this.bolnica, 'McBlueGlass');
                cloneMaterial(this.bolnica, 'DOU_white');

                this.bolnica.userData.hoverColor = 0x43ff4f;
                this.raycastableObjects.push(...this.bolnica.children);
            }

            const poliklinika = gltf.scene.getObjectByName('poliklinika');
            if (poliklinika) {
                /**
                 * Клонируем, чтобы нормально работал рэйкастинг.
                 */
                cloneMaterial(poliklinika, 'McBlueGlass');
                cloneMaterial(poliklinika, 'poliklinika_black');

                poliklinika.userData.hoverColor = 0x0098ff;
                this.raycastableObjects.push(...poliklinika.children);
            }

            const school = gltf.scene.getObjectByName('school');
            if (school) {
                /**
                 * Клонируем, чтобы нормально работал рэйкастинг.
                 */
                cloneMaterial(school, 'McBlueGlass');

                school.userData.hoverColor = 0xff1200;
                this.raycastableObjects.push(...school.children);
            }

            const DOU = gltf.scene.getObjectByName('DOU');

            if (DOU) {
                /**
                 * Клонируем, чтобы нормально работал рэйкастинг.
                 */
                cloneMaterial(DOU, 'McBlueGlass');

                DOU.userData.hoverColor = 0x43ff4f;
                this.raycastableObjects.push(...DOU.children);
            }

            const CCK = gltf.scene.getObjectByName('CCK');
            if (CCK) {
                /**
                 * Клонируем, чтобы нормально работал рэйкастинг.
                 */
                cloneMaterial(CCK, 'McBlueGlass');

                CCK.userData.hoverColor = 0xff008f;
                this.raycastableObjects.push(...CCK.children);
            }

            const cultureCenter = gltf.scene.getObjectByName('pechatniki_pechatniki');
            if (cultureCenter) {
                console.log(cultureCenter);

                cultureCenter.userData.hoverColor = 0xfa3876;
                this.raycastableObjects.push(...cultureCenter.children);
            }

            if (this.bolnica) {
                this.camera?.lookAt(this.bolnica.position);

                if (this.controls) {
                    this.controls.target = this.bolnica.position;
                }
            }

            /**
             * Text
             */

            function loadTexture(imageLoader: ImageLoader | ImageBitmapLoader, url: string): Promise<Texture> {
                return new Promise((resolve) => {
                    imageLoader.load(
                        url,
                        (img) => {
                            const texture = new Texture();
                            texture.image = img;
                            texture.format = RGBAFormat;
                            texture.wrapS = RepeatWrapping;
                            texture.wrapT = RepeatWrapping;
                            texture.needsUpdate = true;
                            resolve(texture);
                        },
                        undefined,
                        (err) => {
                            console.log(err);
                        },
                    );
                });
            }

            this.hints = [];

            loadTexture(this.imageLoader, `${PUBLIC_PATH}bmfonts/Microsoft_YaHei/custom.png`).then((fontAtlas) => {
                loadFont(`${PUBLIC_PATH}bmfonts/Microsoft_YaHei/custom-msdf.json`, (err: any, font: any) => {
                    [
                        {
                            text: 'Больница',
                            parentName: 'bolnica',
                            position: [-10, 17, -4],
                        } as Hint,
                        {
                            text: 'Поликлиника',
                            parentName: 'poliklinika',
                            position: [-3, 5, -4],
                        } as Hint,
                        {
                            text: 'Школа',
                            parentName: 'school',
                            position: [5, 5, 2],
                        } as Hint,
                        {
                            text: 'Детский сад',
                            parentName: 'DOU',
                            position: [4, 5, 4],
                        } as Hint,
                        {
                            text: 'ССК',
                            parentName: 'CCK',
                            position: [3, 5, 5.5],
                        } as Hint,
                        {
                            text: 'Культурно-досуговый центр',
                            parentName: 'pechatniki_pechatniki',
                            position: [-0.5, 5, 3],
                        } as Hint,
                    ].forEach((obj) => {
                        const hint = new Mark({ font, fontAtlas, text: obj.text, position: obj.position });
                        const parent = gltf.scene.getObjectByName(obj.parentName);
                        if (parent) {
                            hint.position.x = parent.position.x + obj.position[0];
                            hint.position.y = parent.position.y + obj.position[1] - 1;
                            hint.position.z = parent.position.z + obj.position[2];
                        }
                        this.hints.push(hint);
                        gltf.scene.add(hint);
                    });

                    this.hideMarks();
                });
            });

            /**
             * Make shadows static
             */
            // this.renderer.shadowMap.autoUpdate = false;
            // this.renderer.shadowMap.needsUpdate = true;

            if (this.state.USE_POSTPROCESSING) {
                initPostprocessing(this);
            }

            this.animate();
        });
    }

    waitForInitialAnimationSignal(callback: () => void) {
        this._waitForInitialAnimationSignalCallback = callback;
    }

    waitForStart(callback: () => void) {
        this._waitForStartCallback = callback;
    }

    setPointer(x: number, y: number) {
        this.state.pointer.x = x;
        this.state.pointer.y = y;
        this.scheduleRendering();
    }

    showMarks() {
        this.hints.forEach((hint) => {
            hint.visible = true;
        });
    }

    hideMarks() {
        this.hints.forEach((hint) => {
            hint.visible = false;
        });
    }

    destroy() {
        cancelAnimationFrame(this.rAF);
        clearTimeout(this.scheduleRenderingTimeout);
    }

    resetHover() {
        if (this.INTERSECTED) {
            for (let i = 0; i < this.INTERSECTED.parent.children.length; i++) {
                this.INTERSECTED.parent.children[i].material.emissive.setHex(0x000000);
            }
        }
    }

    render() {
        this.controls?.update();

        if (this.hints) {
            for (let i = 0; i < this.hints.length; i += 1) {
                const hint = this.hints[i];
                const text = hint.getObjectByName('Mark_text');

                if (text) {
                    text.position.x = Math.cos(this.camera.rotation.x) - 0.5;
                    text.position.z = -Math.sin(this.camera.rotation.z);
                    text.rotation.x = this.camera.rotation.x;
                    text.rotation.y = this.camera.rotation.y;
                    text.rotation.z = this.camera.rotation.z;
                }
            }
        }

        if (this.bolnica) {
            this.state.lookAt.x = lerp(
                this.state.lookAt.x,
                this.bolnica.position.x + this.state.pointer.x * this.state.cameraMovementMult,
                0.09,
            );
            this.state.lookAt.y = lerp(
                this.state.lookAt.y,
                this.bolnica.position.y + this.state.pointer.y * this.state.cameraMovementMult,
                0.09,
            );
            this.state.lookAt.z = this.bolnica.position.z;
            this.camera.lookAt(this.state.lookAt);
        }

        // Updating gltf animations

        // if (this.gltf) {
        //     this.mixer.update(this.clock.getDelta());
        // }

        // Updating DOF focus

        // if (this.bolnica && this.postprocessing?.bokeh) {
        //     const targetDistance = this.camera.position.distanceTo(this.bolnica.position);
        //     this.distance += targetDistance - this.distance;
        //     const sdistance = this.smoothstep(this.camera.near, this.camera.far, this.distance);
        //     const ldistance = this.linearize(1 - sdistance);
        //     this.postprocessing.bokeh.uniforms.focus.value = ldistance;
        // }

        // Updating camera timetime

        if (this.cameraTl) {
            const progress = lerp(this.cameraTl.progress(), this.state.progress, 0.09);
            this.cameraTl.progress(progress, true);
        }

        // Raycasting

        if (this.state.allowRaycasting && this.raycaster) {
            this.raycaster.setFromCamera(this.state.pointer, this.camera);
            const intersects = this.raycaster.intersectObjects(this.raycastableObjects, true);

            if (intersects.length > 0) {
                if (this.INTERSECTED !== intersects[0].object) {
                    this.resetHover();

                    this.INTERSECTED = intersects[0].object;
                    const color = this.INTERSECTED.parent.userData.hoverColor;
                    for (let i = 0; i < this.INTERSECTED.parent.children.length; i++) {
                        this.INTERSECTED.parent.children[i].material.emissive.setHex(color || 0x43ff4f);
                    }
                }
            } else {
                this.resetHover();
                this.INTERSECTED = null;
            }
        }

        // Render

        if (this.composer) {
            this.composer.render();
        } else {
            this.renderer.render(this.scene, this.camera);
        }
    }

    animate() {
        if (this.state.shouldRender) {
            this.state.delta += this.clock.getDelta();

            if (this.state.delta > this.state.renderInterval) {
                this.render();
                this.state.delta %= this.state.renderInterval;
            }
        }

        this.rAF = requestAnimationFrame(this.animate);
    }

    scheduleRendering() {
        clearTimeout(this.scheduleRenderingTimeout);
        this.state.shouldRender = true;
        this.scheduleRenderingTimeout = setTimeout(() => {
            this.state.shouldRender = false;
        }, 2500);
    }

    onResize({ width, height }: { width: number; height: number }) {
        this.state.width = width;
        this.state.height = height;
        this.renderer.setSize(width, height);

        if (this.composer) {
            this.composer.setSize(width, height);
        }

        if (this.postprocessing?.ssao) {
            this.postprocessing.fxaa.width = width * this.state.dpr;
            this.postprocessing.fxaa.height = height * this.state.dpr;
        }

        if (this.postprocessing?.fxaa) {
            this.postprocessing.fxaa.material.uniforms.resolution.value.x = 1 / (width * this.state.dpr);
            this.postprocessing.fxaa.material.uniforms.resolution.value.y = 1 / (height * this.state.dpr);
        }

        if (this.camera) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        }

        this.scheduleRendering();
    }

    click(callbackFn: (name: string) => void) {
        if (this.INTERSECTED) {
            callbackFn(this.INTERSECTED.parent.name);
        }
    }

    // linearize(depth: number) {
    //     const zfar = this.camera.far;
    //     const znear = this.camera.near;
    //     return (-zfar * znear) / (depth * (zfar - znear) - zfar);
    // }

    // smoothstep(near: number, far: number, depth: number) {
    //     const x = this.saturate((depth - near) / (far - near));
    //     return x * x * (3 - 2 * x);
    // }

    // saturate(x: number) {
    //     return Math.max(0, Math.min(1, x));
    // }

    setScrollProgress(progress: number) {
        this.state.progress = progress;
        this.scheduleRendering();
    }

    /**
     * GUI
     */

    setRendererProps({ toneMapping, toneMappingExposure, shadows }: Record<string, any>) {
        this.renderer.toneMapping = Number(toneMapping);
        this.renderer.toneMappingExposure = toneMappingExposure;
        this.renderer.shadowMap.enabled = shadows;
    }

    setCameraProps({ fov, posX, posY, posZ, rotX, rotY, rotZ }: Record<string, number>) {
        if (this.camera) {
            this.camera.fov = fov;
            this.camera.position.x = posX;
            this.camera.position.y = posY;
            this.camera.position.z = posZ;
            this.camera.rotation.x = rotX;
            this.camera.rotation.y = rotY;
            this.camera.rotation.z = rotZ;

            if (this.gltf && this.bolnica) {
                this.camera.lookAt(this.bolnica.position);
            }

            this.camera.updateProjectionMatrix();
        }
    }

    setEnvMapProps({ intensity }: Record<string, number>) {
        if (this.gltf) {
            this.gltf.scene.traverse((child) => {
                if (child instanceof Mesh) {
                    child.material.envMapIntensity = intensity;
                }
            });
        }
    }

    setDirectionalLightProps({
        intensity,
        posX,
        posY,
        posZ,
        shadowCameraTop,
        shadowCameraRight,
        shadowCameraBottom,
        shadowCameraLeft,
        shadowCameraNear,
        shadowCameraFar,
        shadowMapSize,
    }: Record<string, any>) {
        if (this.directionalLight) {
            this.directionalLight.intensity = intensity;
            this.directionalLight.position.x = posX;
            this.directionalLight.position.y = posY;
            this.directionalLight.position.z = posZ;
            this.directionalLight.shadow.mapSize.width = Number(shadowMapSize);
            this.directionalLight.shadow.mapSize.height = Number(shadowMapSize);
            this.directionalLight.shadow.camera.near = shadowCameraNear;
            this.directionalLight.shadow.camera.far = shadowCameraFar;
            this.directionalLight.shadow.camera.top = shadowCameraTop;
            this.directionalLight.shadow.camera.right = shadowCameraRight;
            this.directionalLight.shadow.camera.bottom = shadowCameraBottom;
            this.directionalLight.shadow.camera.left = shadowCameraLeft;
            this.directionalLight.shadow.camera.updateProjectionMatrix();

            if (this.gltf) {
                this.directionalLight.target = this.gltf.scene;
            }
        }
    }

    setSkyProps({ azimuth, elevation, turbidity, rayleigh, mieCoefficient, mieDirectionalG }: Record<string, number>) {
        if (this.sky && this.skyParameters) {
            this.skyParameters.azimuth = azimuth;
            this.skyParameters.elevation = elevation;
            this.sky.material.uniforms.turbidity.value = turbidity;
            this.sky.material.uniforms.rayleigh.value = rayleigh;
            this.sky.material.uniforms.mieCoefficient.value = mieCoefficient;
            this.sky.material.uniforms.mieDirectionalG.value = mieDirectionalG;
        }
    }

    setFogProps({ enabled, near, far, color }: Record<string, any>) {
        this.scene.fog = enabled ? new Fog(color, near, far) : null;
    }

    setSSAOProps({ enabled, kernelRadius, minDistance, maxDistance }: Record<string, any>) {
        if (this.postprocessing) {
            this.postprocessing.ssao.enabled = enabled;
            this.postprocessing.ssao.kernelRadius = kernelRadius;
            this.postprocessing.ssao.minDistance = minDistance;
            this.postprocessing.ssao.maxDistance = maxDistance;
        }
    }
}

export default Canvas;
