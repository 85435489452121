import { InstancedMesh, Object3D } from 'three';
import type { Canvas } from './offscreen-module';
import data from './data.json';

export function createInstances(_module: Canvas) {
    const dummy = new Object3D();

    const ALL_INSTANCES =
        _module.options.gpuTier.gpu === 'apple gpu (Apple GPU)' ||
        (_module.options.gpuTier.fps ? _module.options.gpuTier.fps >= 25 : _module.options.gpuTier.tier > 1);

    if (_module.gltf) {
        [
            'StreetLamp',
            'House1',
            'House2',
            'House3',
            'House4',
            'House5',
            'House6',
            'House7',
            'House8',
            'lavka',
            'Svetofor',
            'Tree1',
            'Tree2',
            'Tree3',
            'Tree4',
            'Urna',
        ].forEach((objName) => {
            const sourceObject = _module.gltf!.scene.getObjectByName(objName) as Object3D | null;

            if (sourceObject) {
                const regex = new RegExp(`^${objName}[0-9]+`);

                sourceObject.children.forEach((child) => {
                    let i = 0;
                    const placeholders = data[objName][child.name];

                    const instancedMesh = new InstancedMesh(child.geometry, child.material, placeholders.length);
                    instancedMesh.frustumCulled = false;
                    instancedMesh.name = `${objName}_instance`;
                    _module.gltf!.scene.add(instancedMesh);

                    for (let x = 0; x < placeholders.length; x++) {
                        const posObj = placeholders[x];
                        dummy.position.copy(posObj.position);
                        dummy.rotation.copy(posObj.rotation);
                        dummy.scale.copy(sourceObject.scale);
                        dummy.updateMatrix();
                        instancedMesh.setMatrixAt(i++, dummy.matrix);
                    }
                });

                sourceObject.visible = false;
            }
        });
    }
}
